<template>
  <div class="page__wrapper">
    <ag-grid
      ref="dealerGradeGrid"
      pagination
      edit-type="fullRow"
      style="width:100%;height:100%;"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :framework-components="frameworkComponents"
      :pagination-page-size="tablePageInfo.pageSize"
      :grid-options="gridOptions"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
          <el-button
            v-permission="'thirdParty_dealerGrade:export'"
            type="primary"
            @click="handleExportClick"
          >
            {{ $t('operation.export') }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
  </div>
</template>

<script>
import Vue from 'vue'
import pageMixins from '@/components/base/simple-table/mixin'

const BASEURL = {
  list: '/jlr/tt/dealerScore/list',
  update: '/jlr/tt/dealerScore/update',
  download: '/jlr/tt/dealerScore/export',
  office: '/system/office/list'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: [ 'handleDataSearch', 'handleSaveClick' ],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.save')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-circle-check" v-permission="'thirdParty_dealerGrade:edit'"
          @click="handleSaveClick(params)" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('operation.cancel')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-refresh-left" @click="handleDataSearch"
          v-permission="'thirdParty_dealerGrade:edit'" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'DealerGrade',
  mixins: [pageMixins],
  data () {
    return {
      searchModel: { period: this.$moment().subtract(1, 'months').format('YYYYMM') },
      tableData: [],
      rowData: {},
      dealerList: [],
      groupList: [],
      gridOptions: {},
      isViewFlag: false,
      groupLoading: false,
      frameworkComponents: {}
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleExportClick: this.handleExportClick,
      handleSaveClick: this.handleSaveClick
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'period',
          type: 'Datepicker',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('dealer_finance.month'),
          component: { clearable: false, type: 'month', valueFormat: 'yyyyMM' }
        },
        {
          prop: 'region',
          type: 'Select',
          col: { xs: 4, sm: 4, md: 4 },
          component: { optionList: this.$getDictList('sys_office_region'), clearable: true },
          label: this.$t('dealer_finance.area')
        },
        {
          prop: 'dealerParent',
          type: 'SearchableInput',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('dealer_finance.group'),
          component: {
            remoteMethod: this.getGroupList,
            loading: this.groupLoading,
            optionList: this.groupList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getGroupList()
            }
          }
        },
        {
          prop: 'dealer',
          label: this.$t('dealer_finance.dealer'),
          type: 'SearchableInput',
          col: { xs: 4, sm: 4, md: 4 },
          component: {
            remoteMethod: this.getDealerList,
            loading: this.dealerLoading,
            optionList: this.dealerList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getDealerList()
            }
          }
        },
        { slotName: 'tableOperation', col: { xs: 8, sm: 8, md: 8 }, style: { textAlign: 'right' }, labelWidth: '0' }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_finance.dealerName'),
          field: 'dealer.name',
          pinned: 'left',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.dealerCode'),
          field: 'dealer.code',
          pinned: 'left',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.area'),
          field: 'region',
          valueFormatter: params => this.$getDictLabel({ type: 'sys_office_region', value: params.value }),
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.group'),
          field: 'dealerParent',
          valueFormatter: params => params.data.dealerParent ? (params.data.dealerParent.name || '') : '',
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return (valueA && valueB) ? valueA.name.localeCompare(valueB.name) : -1
          },
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.cycle'),
          field: 'period',
          valueFormatter: params => params.value ? this.$moment(params.value, 'YYYYMM').format('YYYY年MM月') : '',
          minWidth: 160
        },
        {
          headerName: this.$t('thirdParty_dealerGrade.submitData'),
          field: 'submitData',
          valueFormatter: params => this.$getDictLabel({ type: 'yes_no', value: params.value }),
          minWidth: 160
        },
        {
          headerName: this.$t('thirdParty_dealerGrade.replyMail'),
          field: 'replyMail',
          editable: true,
          type: 'Select',
          cellEditorParams: {
            componentProp: {
              optionList: this.$getDictList('yes_no').map(item => {
                return { value: item.value, label: item.label }
              })
            }
          },
          valueFormatter: params => {
            return this.$getDictLabel({ type: 'yes_no', value: params.value })
          },
          minWidth: 120
        },
        {
          headerName: this.$t('thirdParty_dealerGrade.uploadSupportingDocuments'),
          field: 'uploadSupportingDocuments',
          editable: true,
          type: 'Select',
          cellEditorParams: {
            componentProp: {
              optionList: this.$getDictList('yes_no').map(item => {
                return { value: item.value, label: item.label }
              })
            }
          },
          valueFormatter: params => {
            return this.$getDictLabel({ type: 'yes_no', value: params.value })
          },
          minWidth: 160
        },
        {
          headerName: this.$t('thirdParty_dealerGrade.confirmReview'),
          field: 'confirmReview',
          valueFormatter: params => {
            return this.$getDictLabel({ type: 'yes_no', value: params.value })
          },
          minWidth: 160
        },
        {
          headerName: this.$t('thirdParty_dealerGrade.score'),
          field: 'score',
          valueGetter: params => {
            const isScore = (params.data.submitData === 'Y') && (params.data.replyMail === 'Y') &&
              (params.data.uploadSupportingDocuments === 'Y') && (params.data.confirmReview === 'Y')
            return isScore ? 'Y' : 'N'
          },
          valueFormatter: params => {
            return this.$getDictLabel({ type: 'yes_no', value: params.value })
          },
          minWidth: 200
        },
        {
          headerName: this.$t('field.operation'),
          suppressSizeToFit: true,
          width: 80,
          pinned: 'right',
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  mounted () {
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      if (this.searchModel.dealer) {
        params.filter.push({ left: 'dealer.id', operate: '=', right: this.searchModel.dealer.id })
      }
      if (this.searchModel.dealerParent) {
        params.filter.push({ left: 'dealerParent.id', operate: '=', right: this.searchModel.dealerParent.id })
      }
      params.page = { orderBy: 'createDate' }
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleExportClick (row) {
      const params = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      if (this.searchModel.dealer) {
        params.filter.push({ left: 'dealer.id', operate: '=', right: this.searchModel.dealer.id })
      }
      if (this.searchModel.dealerParent) {
        params.filter.push({ left: 'dealerParent.id', operate: '=', right: this.searchModel.dealerParent.id })
      }
      params.page = { orderBy: 'createDate' }
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.download, params)
        .then(resp => {
          this.$message({ type: 'success', message: this.$t('tip.exportSuccess') })
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleSaveClick (row) {
      this.$refs.dealerGradeGrid.validate(row, status => {
        if (status) {
          const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
          this.$axios
            .post(BASEURL.update, row.data)
            .then(resp => {
              this.handleDataSearch()
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },
    getDealerList (name) {
      this.dealerLoading = true
      const params = {
        filter: [{ left: 'category', operate: '=', right: 'sys_office_category_3' }]
      }
      if (name) {
        params.filter.push({
          left: { left: 'name', operate: 'like', right: name },
          operate: 'or',
          right: { left: 'code', operate: 'like', right: name }
        })
      }
      this.$axios
        .post(BASEURL.office, params)
        .then(resp => {
          const respData = resp.data
          this.dealerList = respData.list.map(item => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.dealerLoading = false
        })
    },
    getGroupList (name) {
      this.groupLoading = true
      const params = {
        filter: [{ left: 'category', operate: '=', right: 'sys_office_category_2' }]
      }
      if (name) {
        params.filter.push({
          left: { left: 'name', operate: 'like', right: name },
          operate: 'or',
          right: { left: 'nameEn', operate: 'like', right: name }
        })
      }
      this.$axios
        .post(BASEURL.office, params)
        .then(resp => {
          const respData = resp.data
          this.groupList = respData.list.map(item => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.groupLoading = false
        })
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>
